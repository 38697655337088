import { graphql, PageProps } from "gatsby"
import React from "react"
import DefaultLayout from "../components/layouts/DefaultLayout"
import ProductPage from "../components/pages/ProductPage"
import { ProductLandingPageCta } from "../components/pages/productPage/ProductPageContent"

const PimProductPageTemplate = (props: PageProps<Queries.ProductPageQuery, Queries.ProductPageQueryVariables>) => {
  //console.debug("Rendering PimProduct", props)

  const { product } = props.data

  if (!product) {
    return null
  }

  // buildattu tuote
  return (
    <DefaultLayout mainMenu={props.data.website?.mainMenu} footer={props.data.website?.footer} seoMetaTags={null}>
      <ProductPage
        sku={product.identifier}
        data={product}
        landingPages={
          [props.data.cta_family?.nodes, props.data.cta_sku?.nodes].filter(Boolean).flat() as ProductLandingPageCta[]
        }
      />
    </DefaultLayout>
  )
}

export const query = graphql`
  query ProductPage(
    $locale: String!
    $sku: String!
    $localePim: String!
    $landing_pages_product_family: JSON!
    $landing_pages_sku: JSON!
    $fallbackLocales: [String!]
  ) {
    website: datoCmsWebsite(locale: $locale, fallbackLocales: $fallbackLocales) {
      mainMenu {
        ...DatoMainNavTree
      }
      footer {
        ...DatoFooter
      }
    }
    product: harviaProduct(identifier: { eq: $sku }, locale: { eq: $localePim }) {
      ...ProductPageHarviaProduct
    }

    cta_family: allDatoCmsProductLandingPage(
      filter: { relatedProductFamilies: { ne: null, glob: $landing_pages_product_family }, locales: { eq: $locale } }
    ) {
      nodes {
        ...ProductLandingPageCta
      }
    }
    cta_sku: allDatoCmsProductLandingPage(
      filter: { relatedProducts: { ne: null, glob: $landing_pages_sku }, locales: { eq: $locale } }
    ) {
      nodes {
        ...ProductLandingPageCta
      }
    }
  }
`

export default PimProductPageTemplate
